import { createAPI } from "../utils/request"

export const Addgroup = data => createAPI('/acl/group/create/', 'post', data) //创建用户组

export const Grouplist = data => createAPI('/acl/group/list', 'get', data) //获取用户组列表

export const Editgroup = data => createAPI('/acl/group/save/', 'post', data) //编辑用户组

export const Delgroup = data => createAPI(`/acl/group/delete/${data.id}`, 'get', data) //删除用户组

export const Checkgroup = data => createAPI(`/acl/group/get/${data.id}`, 'get', data) //获取用户组组信息



