<template>
  <div class="q-pa-md q-gutter-sm">
    <!-- 面包屑 -->
    <q-breadcrumbs>
        <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
        <q-breadcrumbs-el label="权限管理" icon="iconfont icon-quanxian" />
        <q-breadcrumbs-el label="用户组管理" icon="iconfont icon-duoren" to="/group/list" />
    </q-breadcrumbs>
    <!-- 总卡片区 -->
    <!-- 搜索卡片区 -->
    <q-card class="my-card">
      <q-card-section>
        <div class="row">
            <div class="col-9 search">
              <q-input outlined :dense='true' class='operation' style="width: 15.625rem;" v-model="queryData.GroupName" label="用户组名称" />
              <q-btn
                :disable='Permissionslist.filter(item=>item==="group.list").length>0?false:true'
                class='operation'
                color="blue-6"
                label="重置"
                @click="chongzhi"
              />
              <q-btn
                :disable='Permissionslist.filter(item=>item==="group.list").length>0?false:true'
                class='operation'
                color="blue-6"
                label="搜索"
                @click='getGroupList' 
              />
            </div>
            <div class="col addbtn">
              <q-btn
               class='operation'
               :disable='Permissionslist.filter(item=>item==="group.add").length>0?false:true'
               color="blue-6"
               label="新增用户组"
               @click='Groupadd' 
              />
            </div>
        </div>
      </q-card-section>
    </q-card>
    <!-- 总卡片区 -->
    <q-card class="my-card">
      <!-- 搜索框区域 -->
      <q-card-section>
          <el-table
            :data="GroupList"
            style="width: 100%;margin-bottom: 20px;"
            row-key="id"
            stripe
            header-align='center'
            :tree-props="{children: 'children'}"
            >
            <el-table-column prop="groupName" label="用户组名称" ></el-table-column>
            <el-table-column prop="groupDesc" label="用户组描述" ></el-table-column>
            
            <el-table-column prop="date" label="操作"  width="300">
              <template slot-scope="scope">
                  <q-btn 
                    :disable='Permissionslist.filter(item=>item==="group.edit").length>0?false:true'
                    class="czbtn" 
                    size="sm" 
                    color="blue" 
                    icon="iconfont icon-Edit" 
                    @click='editgroups(scope.row)'>
                  </q-btn>
                  <q-btn 
                    :disable='scope.row.children.length>0 || Permissionslist.filter(item=>item==="group.delete").length==0 ? true : false' 
                    size="sm" 
                    color="red-6" 
                    icon="iconfont icon-ashbin"  
                    @click='delGroup(scope.row)'>
                  </q-btn>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <!-- <elementPagination :total='total' :queryData='queryData' @getList='getPermissionList()' @changepageSize='changepageSize()' @changePage='changePage()'/> -->
          
      <!-- 列表区域结束 -->
      </q-card-section>
    </q-card>
    <el-dialog :visible.sync="addconfirm" title="新增用户组" :close-on-click-modal='false' width="30%" @close='addclose'>
      <groupadd 
        ref="addGroup"
        :shape.sync='shape'
        :defaultExpandedKey='defaultExpandedKey'
        :GroupList='GroupList'
        :addGroup='addGroup' 
        @addconfirm='addclose' 
        @addgroupsuccess='getGroupList()' 
        @editconfirm='addclose'>
      </groupadd>
    </el-dialog>
    <el-dialog :visible.sync="editconfirm" title="编辑用户组" :close-on-click-modal='false' width="30%" @close='editclose'>
      <groupedit 
        ref="editGroup"
        :shape.sync='shape'
        :GroupList='editGroupList'
        :defaultExpandedKey='defaultExpandedKey'
        :editGroup='editGroup' 
        :AllGroupName.sync='AllGroupName'
        @editclose='editconfirm=false' 
        @editgroupsuccess='getGroupList()'>
      </groupedit>
    </el-dialog>
  </div>
  
</template>

<script>
import elementPagination from '@/components/ElementUI/elementPagination.vue' // 分页组件
import groupadd from '@/components/Group/Addgroup.vue' // 添加资源组件
import groupedit from '@/components/Group/Editgroup.vue' // 添加资源组件
// import { treeTiled } from '@/api/public.js'
import { mapState } from "vuex"
import { makeDebounce } from "@/utils/debounce.js";

import { Grouplist,Delgroup,Checkgroup } from '@/api/Group.js';
import { notifys } from '@/api/components/Notify.js'

export default {
  created(){
    this.getGroupList()
  },
  data() {
    return {
        queryData: {
          GroupName: '',
          // 当前页码
          page: 1,
          // 每页显示多少条
          pageSize: 10,
          groupId: '' // 用户组id
        },
        total: 0, // 总条数
        GroupList: [], // 用户组列表
        editGroupList: [], // 修改时用户组列表

        //添加用户所需信息
        addGroup:{
          groupName:'',
          groupDesc:'',
        },
        shape: '是',
        addmodel:'',
        defaultExpandedKey:[],
        AllGroupName:'', // 父级用户组的名称

        // 编辑用户所需资料
        editGroup:{},
        TiledGroupList:[], // 所有用户组的平铺信息

        addconfirm: false, // 控制新增弹框
        editconfirm: false// 控制修改弹窗
    };
  },
    components:{
      elementPagination,
      groupadd,
      groupedit
    },
    watch: {},
    computed: {
      ...mapState([
        'Permissionslist' // 权限词典
      ])
    },
    methods: {
      // 获取用户组列表 excludeGroup
      async getGroupList(){
          const { data: res } = await Grouplist({
            groupName: this.queryData.GroupName
          })
          console.log(res);
          this.GroupList = res.data.groups
          this.total = res.data.groups.length
          this.treeToArray(this.GroupList)
          console.log(this.TiledGroupList);
      },
      
       // 利用递归函数 将树状资料全部平铺
      treeToArray (tree) {
        let arr= [];
        const expanded = datas => {
            if (datas&& datas.length > 0){
                datas.forEach(e => {
                    arr.push(e);
                    expanded(e.children);
                })
            }
        };
        expanded(tree);
        return this.TiledGroupList=arr;
      },

      // 点击重置
      chongzhi:makeDebounce(function(){
        this.queryData.GroupName=''
        this.getGroupList()
      }),
      // 点击新增
      Groupadd(a){
        this.addconfirm=true;
        console.log(a);
      },
      // 点击修改时获取资源
      editgroups:makeDebounce(async function(row){
        const { data: res} = await Checkgroup({id:row.id})
        console.log(res);
        if(res.code===20000){
          const { data: group } = await Grouplist({
            excludeGroup: res.data.group.id
          })
          if(group.code===20000){
            this.editGroupList = group.data.groups
            this.editconfirm = true;
            this.editGroup = res.data.group
            if(this.editGroup.pid!=0){
              this.shape='否'
            }else{
              this.shape='是'
            }
            // 获取父级名称
            this.AllGroupName=''
            const tickeds = this.editGroup.pid // 获取到所点击项 的父项id值
            const AllGroups = this.TiledGroupList
            console.log(tickeds);
            AllGroups.forEach(Gname=>{
              if(tickeds===Gname.id){
                return this.AllGroupName = Gname.groupName
              }
            })
            console.log(this.AllGroupName);
          }
        }
      },200),
      // 点击删除
      delGroup(row){
        console.log(row);
        this.$q.dialog({
          title: '删除',
          message: `你确定要删除用户组: ${row.groupName} 吗?`,
          ok: { label: '确定',color: 'red',},
          cancel: { label: '取消',flat: true},
        }).onOk(async() => {
          try{
              const {data:res} = await Delgroup({id:row.id})
              console.log(res);
              if(res.code===20000){
                this.getGroupList()
                notifys('positive',res.message)
              }else{
                notifys('negative',res.message)
              }
            }catch (e){
                console.log(e);
            }
        })
      },
      // 关闭新增窗口时
      addclose(){
        this.addconfirm=false
        this.addGroup.groupName=''
        this.addGroup.groupDesc=''
        this.shape='是'
        this.$refs.addGroup.$refs.myForm.resetValidation()
      },
      // 关闭修改弹窗时
      editclose(){
        this.$refs.editGroup.$refs.myForm.resetValidation()
        this.shape='是'
      },
      // 切换分页时触发
      changepageSize(val){
        this.queryData.pageSize = val
        this.getGroupList()
      },
      changePage(val){
        this.queryData.page = val
        this.getGroupList()
      }
    },
};
</script>

<style lang='less' scoped>
.search{
  display: flex;
  flex-direction: row ;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1.25rem;
  .operation{
    margin-right: .625rem;
    margin-left: .625rem;
  }
}
.addbtn{
  display: flex;
  flex-direction: row ;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1.25rem;
}
.czbtn{
  margin-right: .75rem;
}
</style>
