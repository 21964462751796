<template>
  <div>
    <q-form ref="myForm" class="q-gutter-md add">
        <q-input
            class="add"
            :dense="true"
            outlined
            v-model="editGroup.groupName"
            label="用户组名称*"
            lazy-rules
            :rules="[required.must,required.groupName]"
        />
        <q-input
            class="add"
            :dense="true"
            outlined
            v-model="editGroup.groupDesc"
            label="用户组描述"
            lazy-rules
            :rules="[required.groupDesc]"
        />
        <div style='margin-left: 0;'>
            顶级组: <q-radio v-model="editshape" val="是" label="是" /> <q-radio v-model="editshape" val="否" label="否" />
        </div>
        
        <div v-if='editshape==="否"' class="add">
            <el-select placeholder="父级组" :value="userAllGroupName" :clearable="clearable" @clear="clearHandle" @visible-change='visiblechange'>
                <el-option :value="userAllGroupName" :label="userAllGroupName">
                    <el-tree  id="tree-option"
                        ref="selectTree"
                        :accordion="accordion"
                        :data="GroupList"
                        node-key="id"    
                        :props="defaultProps"
                        :default-expanded-keys="defaultExpandedKey"
                        @node-click="handleNodeClick">
                    </el-tree>
                </el-option>
            </el-select>
        </div>
        
        <div class="addbtn">
            <q-btn flat label="取消" color="primary" v-close-popup @click="quxiao"/>
            <q-btn label="保存" color="blue-6" @click="editGroupList"/>
        </div>
    </q-form>
  </div>
</template>

<script>
import { Editgroup } from '@/api/Group.js';
import { notifys } from '@/api/components/Notify.js'

export default {
    props:{
        editGroup:{
            type: Object,
            required: true
        },
        GroupList:{
            type: Array,
            required: true
        },
        shape:{
            type: String,
            required: true
        },  
        AllGroupName:{
            type: String,
        },
        defaultExpandedKey:{
            type: Array,
            required: true,
        },
    },
    created(){},
    data() {
        return {
            // 表单验证规则
            required: {
                kong: () => {},
                must: (val) =>(val && val.trim().length > 0) || "用户组名称不能为空",
                mustselect: (val) => this.shape==='否' && (val.trim() && val.trim().length > 0) || "必填项,不可为空",
                groupName: (val) => val.trim().length <= 20 || "长度不可大于20个字符",
                groupDesc: (val) => val.trim().length <= 40 || "长度不可大于40个字符",
            },
            accordion: true,
            clearable: true,
            valueTitle:'',
            valueId:this.editGroup.pid,
            defaultProps: {
                children: 'children',
                label: 'groupName'
            },
        };
    },
    components:{},
    computed:{
        // 让父级用户组名称与父组件形成双向绑定
        userAllGroupName: {
            get() {
                return this.AllGroupName
            },
            set(v) {
                this.$emit("update:AllGroupName", v)
            }
        },
        editshape:{
            get(){
                return this.shape
            },
            set(v){
                this.$emit('update:shape',v)
            }
        }
    },
    watch:{
    },
    methods: {
        quxiao(){
            this.$emit("editclose");
            console.log(this.valueId);
            this.$refs.myForm.resetValidation();
        },
        editGroupList(){
            this.$refs.myForm.validate().then( async outcome =>{
                if(outcome){
                    try{
                        if(this.editshape === '是'){
                            this.valueId =0
                        }
                        const { data: res } = await Editgroup({
                            groupName:this.editGroup.groupName,
                            groupDesc: this.editGroup.groupDesc,
                            id: this.editGroup.id,
                            pid: this.valueId
                        })
                        console.log(res);
                        if(res.code===20000){
                            this.$emit('editgroupsuccess') // 表格刷新页面
                            this.$emit("editclose"); // 关闭对话框
                            this.editshape='是'
                            this.$refs.myForm.resetValidation()
                            notifys('positive',res.message)
                        }else{
                            notifys('negative',res.message)
                        }
                    }catch(e){
                        console.log(e);
                    }
                }
            });
        },
        //节点被点击时回调
        handleNodeClick(node) {
            console.log(node);
            this.userAllGroupName = node.groupName
            this.valueId = node.id
            console.log(this.valueId);
            console.log(this.userAllGroupName);
        },
        //点击清空按钮时触发
        clearHandle(){
            this.valueId=0
            this.userAllGroupName = ''
        },
        visiblechange(){
            this.$emit('clearHandles')
        }
    },
};
</script>

<style  scoped>
.adduser {
    width: 21.875rem;
}
.add {
    width: 100%;
    margin-left: 0;
    margin-top: 0.1875rem;
}
.addbtn {
    display: flex;
    justify-content: flex-end;
}
.el-select{
    width: 100%;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item{
height: auto;
max-height: 17.125rem;
padding: 0;
overflow: hidden;
overflow-y: auto;
}
.el-select-dropdown__item.selected{
font-weight: normal;
}
ul li >>>.el-tree .el-tree-node__content{
height:auto;
padding: 0 1.25rem;
}
.el-tree-node__label{
font-weight: normal;
}
.el-tree >>>.is-current .el-tree-node__label{
color: #409EFF;
font-weight: 700;
}
.el-tree >>>.is-current .el-tree-node__children .el-tree-node__label{
color:#606266;
font-weight: normal;
}.adduser {
    width: 21.875rem;
}
.add {
    width: 100%;
    margin-left: 0;
    margin-top: 0.1875rem;
}
.addbtn {
    display: flex;
    justify-content: flex-end;
}
.el-select{
    width: 100%;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item{
height: auto;
max-height: 17.125rem;
padding: 0;
overflow: hidden;
overflow-y: auto;
}
.el-select-dropdown__item.selected{
font-weight: normal;
}
ul li >>>.el-tree .el-tree-node__content{
height:auto;
padding: 0 1.25rem;
}
.el-tree-node__label{
font-weight: normal;
}
.el-tree >>>.is-current .el-tree-node__label{
color: #409EFF;
font-weight: 700;
}
.el-tree >>>.is-current .el-tree-node__children .el-tree-node__label{
color:#606266;
font-weight: normal;
}
</style>
